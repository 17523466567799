import React from 'react'
import { graphql, Link } from 'gatsby'
import TitlePage from '../components/TitlePage'
import SEO from '../components/seo'
import * as S from '../components/Content/styled'
import * as L from '../components/SensorsEquipments/styled'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import Slider from 'react-slick'
import '../components/CSS/slick.css'
import useTranslations from '../components/useTranslations'
import DocumentDownload from '../components/LandingPage/documentDownload'
import LocalizedLink from '../components/LocalizedLink'

const SensorsList = props => {
  const post = props.data.markdownRemark
  const image = post.frontmatter.featuredImage.childImageSharp.fluid
  const secondImage = post.frontmatter.featuredSecondImage.childImageSharp.fluid
  const { retmonTitle, navbar, back } = useTranslations()

  const imageSRC = post.frontmatter.featuredImage.childImageSharp.fluid.src

  const cldURL_1 = post.frontmatter.cloudinaryURL
  const cldURL_2 = post.frontmatter.cloudinaryURL_2

  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: true,
  }

  return (
    <>
      <SEO
        title={`${post.frontmatter.title} | ${retmonTitle}`}
        description={post.frontmatter.description}
        image={cldURL_1 ? cldURL_1 : 'https://www.retmon.com' + imageSRC}
      />
      <L.TitleWrapper>
        <LocalizedLink to={`/${navbar.productsSub.sensorsEquipmentsURL}/`}>
          {back}
        </LocalizedLink>
        <TitlePage text={post.frontmatter.title} />
        <L.SubHeader>{post.frontmatter.subTitle}</L.SubHeader>
      </L.TitleWrapper>
      <Slider {...settings}>
        <div>
          {cldURL_1 ? (
            <a href={cldURL_1} title={post.frontmatter.title}>
              <L.SensorCloudinaryImage
                src={cldURL_1}
                alt={post.frontmatter.title}
              />
            </a>
          ) : (
            <L.SensorImage fluid={image} alt={post.frontmatter.title} />
          )}
        </div>
        {post.frontmatter.singleImage === true ? null : (
          <div>
            {cldURL_2 ? (
              <a href={cldURL_2} title={post.frontmatter.title}>
                <L.SensorCloudinaryImage
                  src={cldURL_2}
                  alt={post.frontmatter.title}
                />
              </a>
            ) : (
              <L.SensorImage fluid={secondImage} alt={post.frontmatter.title} />
            )}
          </div>
        )}
      </Slider>
      <S.Content>
        <div dangerouslySetInnerHTML={{ __html: post.html }}></div>
      </S.Content>
    </>
  )
}

export const query = graphql`
  query Sensor($locale: String!, $title: String!) {
    markdownRemark(
      frontmatter: { title: { eq: $title } }
      fields: { locale: { eq: $locale } }
    ) {
      frontmatter {
        title
        subTitle
        singleImage
        cloudinaryURL
        cloudinaryURL_2
        featuredImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        featuredSecondImage {
          childImageSharp {
            fluid(maxWidth: 800) {
              aspectRatio
              src
              srcSet
              sizes
            }
          }
        }
        page
        date
        subURL
      }
      html
    }
  }
`

export default SensorsList
